import { render, staticRenderFns } from "./Place-left-modal.vue?vue&type=template&id=ec7512d2&scoped=true"
import script from "./Place-left-modal.vue?vue&type=script&lang=js"
export * from "./Place-left-modal.vue?vue&type=script&lang=js"
import style0 from "./Place-left-modal.vue?vue&type=style&index=0&id=ec7512d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec7512d2",
  null
  
)

export default component.exports