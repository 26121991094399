var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row europoints-trips-history-section"},[_vm._m(0),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"table-wrapper table-responsive"},[_c('table',{staticClass:"table align-middle"},[_c('thead',[_c('tr',[_c('th',[_vm._v(" Дата ")]),(!_vm.isMobile())?_c('th',[_vm._v(" Маршрут ")]):_vm._e(),(!_vm.isMobile())?_c('th',[_vm._v(" Итого ")]):_vm._e(),_c('th',[_vm._v(" Использовано ")]),_c('th',[_vm._v(" Получено ")])])]),_c('tbody',[_c('tr',[_vm._m(1),(!_vm.isMobile())?_c('td',[_c('div',{staticClass:"path-trip d-flex align-items-center"},[_c('div',{staticClass:"path-trip-start"},[_vm._v(" Белгород ")]),_c('ArrowPathIcon'),_c('div',{staticClass:"path-trip-end"},[_vm._v(" Ставрополь ")])],1)]):_vm._e(),(!_vm.isMobile())?_c('td',[_c('div',{staticClass:"amount-sum-trip"},[_vm._v(" 4 500₽ ")])]):_vm._e(),_vm._m(2),_vm._m(3)]),_c('tr',[_vm._m(4),(!_vm.isMobile())?_c('td',[_c('div',{staticClass:"path-trip d-flex align-items-center"},[_c('div',{staticClass:"path-trip-start"},[_vm._v(" Ставрополь ")]),_c('ArrowPathIcon'),_c('div',{staticClass:"path-trip-end"},[_vm._v(" Москва ")])],1)]):_vm._e(),(!_vm.isMobile())?_c('td',[_c('div',{staticClass:"amount-sum-trip"},[_vm._v(" 8 500₽ ")])]):_vm._e(),_vm._m(5),_vm._m(6)])])])])]),_c('div',{staticClass:"col-12"},[(!_vm.isMobile())?_c('div',{staticClass:"pagination"},[_vm._m(7)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"title-section"},[_vm._v(" История заказов ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"date-trip"},[_vm._v(" 25.11.2019 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"used-europoints-trip used"},[_vm._v(" 3 000 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"added-europoints-trip"},[_vm._v(" +450 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"date-trip"},[_vm._v(" 25.10.2020 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"used-europoints-trip"},[_vm._v(" 0 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"added-europoints-trip"},[_vm._v(" +150 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"aria-label":"..."}},[_c('ul',{staticClass:"pagination"},[_c('li',{staticClass:"page-item active",attrs:{"aria-current":"page"}},[_c('span',{staticClass:"page-link"},[_vm._v("1")])]),_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v("2")])]),_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v("3")])])])])
}]

export { render, staticRenderFns }