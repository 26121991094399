<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.9151 12.0932C23.106 12.2516 23.106 12.5152 22.9151 12.6735C21.2234 14.0762 16.9553 17.1334 12 17.1334C7.04469 17.1334 2.77662 14.0762 1.08494 12.6735C0.893986 12.5152 0.893985 12.2516 1.08494 12.0932C2.77662 10.6906 7.04469 7.63339 12 7.63339C16.9553 7.63339 21.2234 10.6906 22.9151 12.0932Z" stroke="#B5BDDB" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="12" cy="12.3834" r="4.75" stroke="#B5BDDB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 14.6334C13.2426 14.6334 14.25 13.626 14.25 12.3834C14.25 11.1408 13.2426 10.1334 12 10.1334C11.6526 10.1334 11.3237 10.2121 11.0299 10.3527C11.1659 10.4884 11.25 10.6761 11.25 10.8834C11.25 11.2976 10.9142 11.6334 10.5 11.6334C10.2927 11.6334 10.105 11.5493 9.96928 11.4133C9.82871 11.7071 9.75 12.036 9.75 12.3834C9.75 13.626 10.7574 14.6334 12 14.6334Z" fill="#B5BDDB"/>
    <rect x="17.948" y="0.0683013" width="1.9" height="25.7584" rx="0.95" transform="rotate(30 17.948 0.0683013)" fill="#B5BDDB" stroke="white" stroke-width="0.1"/>
  </svg>
</template>

<script>
export default {
  name: "EyeCloseIcon"
}
</script>

<style scoped>

</style>