<template>
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.207706 12.0369C-0.219875 11.3036 0.0338705 10.366 0.774462 9.94259C1.51505 9.51922 2.46204 9.77046 2.88963 10.5038L6.50257 16.7C6.93016 17.4333 6.67641 18.371 5.93582 18.7943C5.19523 19.2177 4.24824 18.9665 3.82066 18.2332L0.207706 12.0369Z" :fill="color"/>
    <path d="M13.1104 0.766841C13.538 0.0335374 14.4849 -0.217711 15.2255 0.205661C15.9661 0.629034 16.2199 1.56671 15.7923 2.30001L6.50186 18.2332C6.07427 18.9665 5.12728 19.2177 4.38669 18.7943C3.6461 18.371 3.39235 17.4333 3.81994 16.7L13.1104 0.766841Z" :fill="color"/>
  </svg>

</template>

<script>
export default {
  name: "CheckIcon",
  props: ['color'],
}
</script>

<style scoped>

</style>