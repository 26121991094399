<template>
  <svg width="24" height="7" viewBox="0 0 24 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.727272 1.43062C0.32561 1.43062 7.02332e-08 1.11036 7.49442e-08 0.715307C7.96552e-08 0.320254 0.325611 -9.49791e-07 0.727273 -9.45002e-07L23.2727 -6.7615e-07C23.6744 -6.7136e-07 24 0.320254 24 0.715308C24 1.11036 23.6744 1.43062 23.2727 1.43062L0.727272 1.43062Z" fill="#283256"/>
    <path d="M18.1247 6.79047C17.8407 7.06981 17.3802 7.06981 17.0962 6.79047C16.8122 6.51112 16.8122 6.05821 17.0962 5.77887L22.7531 0.215077C23.0371 -0.0642678 23.4976 -0.0642678 23.7816 0.215077C24.0656 0.494422 24.0656 0.94733 23.7816 1.22668L18.1247 6.79047Z" fill="#283256"/>
  </svg>
</template>

<script>
export default {
  name: "ArrowPathIcon"
}
</script>

<style scoped>

</style>