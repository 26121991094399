var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row info-buyer"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-wrapper"},[_c('div',{staticClass:"row my-data-button-row"},[_c('MyDataButton',{staticClass:"my-data-button-component",attrs:{"is-login":_vm.getIsLogin,"is-collapse":false}}),_c('div',{staticClass:"collapse",class:{show : !_vm.getIsLogin},attrs:{"id":"collapseData"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 col-xl mb-lg-4 mb-xl-0"},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"form-label",attrs:{"for":"secondNameBuyer"}},[_vm._v("Фамилия")]),_c('input',{staticClass:"form-control",class:{
                      'is-ok': !_vm.getBuyerInfo.errors.secondName,
                      'is-error' : _vm.getBuyerInfo.errors.secondName},attrs:{"type":"text","id":"secondNameBuyer","placeholder":"Иванов"},domProps:{"value":_vm.getBuyerInfo.secondName},on:{"input":function($event){return _vm.validateFormBuyer(['secondName',$event])}}}),_c('div',{staticClass:"error-feedback",class:{
                      'd-none': !_vm.getBuyerInfo.errors.secondName}},[_vm._v(" "+_vm._s(_vm.getBuyerInfo.errors.secondName)+" ")])])]),_c('div',{staticClass:"col-12 col-lg-6 col-xl mb-lg-4 mb-xl-0"},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"form-label",attrs:{"for":"firstNameBuyer"}},[_vm._v("Имя")]),_c('input',{staticClass:"form-control",class:{
                      'is-ok': !_vm.getBuyerInfo.errors.firstName,
                      'is-error' : _vm.getBuyerInfo.errors.firstName},attrs:{"type":"text","id":"firstNameBuyer","placeholder":"Иван"},domProps:{"value":_vm.getBuyerInfo.firstName},on:{"input":function($event){return _vm.validateFormBuyer(['firstName',$event])}}}),_c('div',{staticClass:"error-feedback",class:{
                      'd-none': !_vm.getBuyerInfo.errors.firstName}},[_vm._v(" "+_vm._s(_vm.getBuyerInfo.errors.firstName)+" ")])])]),_c('div',{staticClass:"col-12 col-lg-6 col-xl"},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"form-label",attrs:{"for":"mailBuyer"}},[_vm._v("Электронная почта")]),_c('input',{staticClass:"form-control",class:{
                      'is-ok': !_vm.getBuyerInfo.errors.email,
                      'is-error' : _vm.getBuyerInfo.errors.email},attrs:{"type":"text","id":"mailBuyer","placeholder":"name@mail.ru"},domProps:{"value":_vm.getBuyerInfo.email},on:{"input":function($event){return _vm.validateFormBuyer(['email', $event]);}}}),_c('div',{staticClass:"error-feedback",class:{
                      'd-none': !_vm.getBuyerInfo.errors.email}},[_vm._v(" "+_vm._s(_vm.getBuyerInfo.errors.email)+" ")])])]),_c('div',{staticClass:"col-12 col-lg-6 col-xl"},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"form-label",attrs:{"for":"numberBuyer"}},[_vm._v("Телефон")]),_c('input',{staticClass:"form-control",class:{
                      'is-ok': !_vm.getBuyerInfo.errors.number,
                      'is-error' : _vm.getBuyerInfo.errors.number},attrs:{"type":"text","pattern":"[0-9]*","inputmode":"numeric","id":"numberBuyer","placeholder":"+7 (___)-___-__-__"},domProps:{"value":_vm.getBuyerInfo.number},on:{"focus":_vm.addRussianNumberPrefix,"input":function($event){return _vm.validateFormBuyer(['number', $event]);}}}),_c('div',{staticClass:"error-feedback",class:{
                      'd-none': !_vm.getBuyerInfo.errors.number}},[_vm._v(" "+_vm._s(_vm.getBuyerInfo.errors.number)+" ")])])])])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"title-section"},[_vm._v(" Информация о покупателе ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6"},[_c('p',{staticClass:"title-section-description"},[_vm._v(" Указывайте корректные e-mail и номер телефона, т.к. они необходимы для получения и возврата билета, а так же для обратной связи. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6"},[_c('p',{staticClass:"title-section-description active-description"},[_vm._v(" Для посадки, необходимо иметь при себе "),_c('span',{staticClass:"fw-normal"},[_vm._v("оригинал указанного документа")]),_vm._v(" и "),_c('span',{staticClass:"fw-normal"},[_vm._v("билет")]),_vm._v(" (будет отправлен на указанную вами эл.почту). ")])])
}]

export { render, staticRenderFns }