import { render, staticRenderFns } from "./FlightTableItemMobile.vue?vue&type=template&id=785bd80a&scoped=true"
import script from "./FlightTableItemMobile.vue?vue&type=script&lang=js"
export * from "./FlightTableItemMobile.vue?vue&type=script&lang=js"
import style0 from "./FlightTableItemMobile.vue?vue&type=style&index=0&id=785bd80a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785bd80a",
  null
  
)

export default component.exports