<template>
  <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3333 1.6H2.66667C2.07756 1.6 1.6 2.07756 1.6 2.66667V21.3333C1.6 21.9224 2.07756 22.4 2.66667 22.4H13.3333C13.9224 22.4 14.4 21.9224 14.4 21.3333V2.66667C14.4 2.07756 13.9224 1.6 13.3333 1.6ZM2.66667 0C1.19391 0 0 1.19391 0 2.66667V21.3333C0 22.8061 1.19391 24 2.66667 24H13.3333C14.8061 24 16 22.8061 16 21.3333V2.66667C16 1.19391 14.8061 0 13.3333 0H2.66667Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3333 1.6H2.66667C2.07756 1.6 1.6 2.07756 1.6 2.66667V3.73333H14.4V2.66667C14.4 2.07756 13.9224 1.6 13.3333 1.6ZM2.66667 0C1.19391 0 0 1.19391 0 2.66667V5.33333H16V2.66667C16 1.19391 14.8061 0 13.3333 0H2.66667Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4 20.2667H1.6V21.3333C1.6 21.9224 2.07756 22.4 2.66667 22.4H13.3333C13.9224 22.4 14.4 21.9224 14.4 21.3333V20.2667ZM0 18.6667V21.3333C0 22.8061 1.19391 24 2.66667 24H13.3333C14.8061 24 16 22.8061 16 21.3333V18.6667H0Z" fill="white"/>
    <path d="M6.66667 21.3333C6.66667 20.9651 6.96514 20.6667 7.33333 20.6667H8.66667C9.03486 20.6667 9.33333 20.9651 9.33333 21.3333C9.33333 21.7015 9.03486 22 8.66667 22H7.33333C6.96514 22 6.66667 21.7015 6.66667 21.3333Z" fill="white"/>
    <path d="M12 2.66667C12 2.29848 12.2985 2 12.6667 2C13.0349 2 13.3333 2.29848 13.3333 2.66667C13.3333 3.03486 13.0349 3.33333 12.6667 3.33333C12.2985 3.33333 12 3.03486 12 2.66667Z" fill="white"/>
    <path d="M4.66667 2.66667C4.66667 2.29848 4.96514 2 5.33333 2H10.6667C11.0349 2 11.3333 2.29848 11.3333 2.66667C11.3333 3.03486 11.0349 3.33333 10.6667 3.33333H5.33333C4.96514 3.33333 4.66667 3.03486 4.66667 2.66667Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "MobileIcon"
}
</script>

<style scoped>

</style>