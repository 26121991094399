<template>
  <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.8906 0H2.10938C0.947719 0 0 0.932123 0 2.07692V15.9231C0 17.064 0.9435 18 2.10938 18H21.8906C23.0494 18 24 17.071 24 15.9231V2.07692C24 0.936 23.0565 0 21.8906 0ZM21.5993 1.38462L12.0447 10.7923L2.40745 1.38462H21.5993ZM1.40625 15.6364V2.35703L8.1787 8.96811L1.40625 15.6364ZM2.40061 16.6154L9.17733 9.94292L11.5519 12.2609C11.8267 12.5292 12.2703 12.5283 12.5441 12.2587L14.8594 9.97906L21.5994 16.6154H2.40061ZM22.5938 15.6363L15.8537 9L22.5938 2.36363V15.6363Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "MailIcon"
}
</script>

<style scoped>

</style>