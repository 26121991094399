<template>
  <svg width="24" height="11" viewBox="0 0 24 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.9151 5.09328C23.106 5.25161 23.106 5.51524 22.9151 5.67357C21.2234 7.07619 16.9553 10.1334 12 10.1334C7.04469 10.1334 2.77662 7.07619 1.08494 5.67357C0.893986 5.51524 0.893985 5.25161 1.08494 5.09328C2.77662 3.69066 7.04469 0.633423 12 0.633423C16.9553 0.633423 21.2234 3.69066 22.9151 5.09328Z" stroke="#B5BDDB" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="12" cy="5.38342" r="4.75" stroke="#B5BDDB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.63342C13.2426 7.63342 14.25 6.62606 14.25 5.38342C14.25 4.14078 13.2426 3.13342 12 3.13342C11.6526 3.13342 11.3237 3.21214 11.0299 3.3527C11.1659 3.48846 11.25 3.67612 11.25 3.88342C11.25 4.29764 10.9142 4.63342 10.5 4.63342C10.2927 4.63342 10.105 4.54931 9.96928 4.41336C9.82871 4.70709 9.75 5.03606 9.75 5.38342C9.75 6.62606 10.7574 7.63342 12 7.63342Z" fill="#B5BDDB"/>
  </svg>
</template>

<script>
export default {
  name: "EyeOpenIcon"
}
</script>

<style scoped>

</style>