<template>
  <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0769 15.5549C9.66786 15.5549 9.33631 15.2313 9.33631 14.8322C9.33631 14.4331 9.66786 14.1404 10.0769 14.1404H14.7052C15.1142 14.1404 15.4458 14.4331 15.4458 14.8322C15.4458 15.2313 15.1142 15.5549 14.7052 15.5549H10.0769Z" v-bind:fill="color"/>
    <path d="M10.8139 6.05794C11.2229 6.05794 11.5544 6.35074 11.5544 6.74987C11.5544 7.14899 11.2229 7.47255 10.8139 7.47255L1.0943 7.47255C0.68531 7.47255 0.35376 7.14899 0.35376 6.74987C0.35376 6.35074 0.68531 6.05794 1.0943 6.05794H10.8139Z" v-bind:fill="color"/>
    <path d="M6.33618 0.61206C6.62538 0.329834 7.09427 0.329834 7.38346 0.61206C7.67266 0.894286 7.67266 1.35186 7.38346 1.63409L1.62342 7.25525C1.33422 7.53748 0.865339 7.53748 0.576141 7.25525C0.286942 6.97303 0.286942 6.51545 0.576141 6.23322L6.33618 0.61206Z" v-bind:fill="color"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.268 7.47238H5.08563V6.05794H15.268C17.9111 6.05794 20.0538 8.18391 20.0538 10.8064C20.0538 13.4289 17.9111 15.5549 15.268 15.5549H11.1951V14.1404H15.268C17.1238 14.1404 18.6282 12.6477 18.6282 10.8064C18.6282 8.96508 17.1238 7.47238 15.268 7.47238Z" v-bind:fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "CancelIcon",
  props: ['color'],
}
</script>

<style scoped>

</style>