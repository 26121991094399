var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row info-passengers"},[_c('div',{staticClass:"col-12"},[_c('nav',[_c('div',{staticClass:"nav nav-tabs",attrs:{"id":"nav-tab","role":"tablist"}},_vm._l((_vm.getPassengers),function(passenger,index){return _c('button',{key:index,staticClass:"nav-link",class:{active: index === _vm.getActiveTab},attrs:{"id":'passenger-'+index+'-tab',"data-bs-toggle":"tab","data-bs-target":'#passenger-'+index,"type":"button","role":"tab","aria-controls":'passenger-'+index,"aria-selected":{true : index === _vm.getActiveTab}},on:{"click":function($event){return _vm.setActiveTab(index)}}},[_vm._v(_vm._s(passenger.isAdult ? "" : '👶')+" Пассажир №"+_vm._s(index+1))])}),0)]),_c('div',{staticClass:"tab-content position-relative",attrs:{"id":"nav-tabContent"}},_vm._l((_vm.getPassengers),function(passenger,index){return _c('div',{key:index,staticClass:"tab-pane fade",class:{'show active': index === _vm.getActiveTab},attrs:{"id":'passenger-'+index,"role":"tabpanel","aria-labelledby":'passenger-'+index+'-tab'}},[_c('div',{staticClass:"form-wrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"checkbox-form d-flex align-items-center w-100"},[_c('MyDataButton',{staticClass:"my-data-button",attrs:{"is-login":_vm.getIsLogin,"is-collapse":false}}),_c('div',{staticClass:"d-inline-block"},[(index !== 0)?_c('button',{staticClass:"remove-button",attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":'#removeModal'+index}},[_vm._v(" Убрать "),_c('CancelIcon',{attrs:{"color":"#1399FF"}})],1):_vm._e()])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 col-lg-6 col-xl-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"secondName"}},[_vm._v("Фамилия")]),_c('input',{staticClass:"form-control",class:{
                        'is-ok': !passenger.errors.secondName,
                        'is-error' : passenger.errors.secondName},attrs:{"type":"text","id":'secondName' + index,"placeholder":"Иванов"},domProps:{"value":passenger.secondName},on:{"input":function($event){return _vm.validateForm([index, 'secondName', $event]);}}}),_c('div',{staticClass:"error-feedback",class:{
                    'd-none': !passenger.errors.secondName}},[_vm._v(" "+_vm._s(passenger.errors.secondName)+" ")])]),_c('div',{staticClass:"col-3 col-lg-6 col-xl-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"firstName"}},[_vm._v("Имя")]),_c('input',{staticClass:"form-control",class:{
                        'is-ok': !passenger.errors.firstName,
                        'is-error' : passenger.errors.firstName},attrs:{"type":"text","id":'firstName'+ index,"placeholder":"Иван"},domProps:{"value":passenger.firstName},on:{"input":function($event){return _vm.validateForm([index, 'firstName', $event]);}}}),_c('div',{staticClass:"error-feedback",class:{
                    'd-none': !passenger.errors.firstName}},[_vm._v(_vm._s(passenger.errors.firstName))])]),_c('div',{staticClass:"col-3 col-lg-6 col-xl-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"MiddleName"}},[_vm._v("Отчество")]),_c('input',{staticClass:"form-control",class:{
                        'is-ok': !passenger.errors.middleName,
                        'is-error' : passenger.errors.middleName},attrs:{"type":"text","id":'MiddleName' + index,"placeholder":"Иванович"},domProps:{"value":passenger.middleName},on:{"input":function($event){return _vm.validateForm([index, 'middleName', $event]);}}}),_c('div',{staticClass:"error-feedback",class:{'d-none': !passenger.errors.middleName}},[_vm._v(_vm._s(passenger.errors.middleName))])]),_c('div',{staticClass:"col-3 col-lg-6 col-xl-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"birthday"}},[_vm._v("Дата рождения")]),_c('input',{staticClass:"form-control",class:{
                        'is-ok': !passenger.errors.birthday,
                        'is-error' :passenger.errors.birthday},attrs:{"type":"date","id":'birthday'+index,"pattern":"\\d{4}-\\d{2}-\\d{2}","placeholder":"дд.мм.гггг","required":""},domProps:{"value":passenger.birthday},on:{"input":function($event){return _vm.validateForm([index, 'birthday', $event, passenger.isAdult])}}}),_c('div',{staticClass:"error-feedback",class:{'d-none': !passenger.errors.birthday}},[_vm._v(_vm._s(passenger.errors.birthday))])])]),_c('div',{staticClass:"row gy-2"},[_c('div',{staticClass:"col-3 col-lg-6 col-xl-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"gender"}},[_vm._v("Пол")]),_c('div',{staticClass:"position-relative"},[_c('select',{staticClass:"form-control form-control-select",class:{'is-ok': !passenger.errors.gender,
                        'is-error' : passenger.errors.gender},attrs:{"id":'gender'+index},domProps:{"value":passenger.gender},on:{"input":function($event){return _vm.validateForm([index, 'gender', $event]);}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("Женский")]),_c('option',{attrs:{"selected":"","value":"1"}},[_vm._v("Мужской")])]),(!passenger.gender)?_c('div',{staticClass:"select-placeholder position-absolute pe-none"},[_vm._v("Мужской")]):_vm._e(),_c('div',{staticClass:"error-feedback",class:{'d-none': !passenger.errors.gender}},[_vm._v(_vm._s(passenger.errors.gender))])])]),_c('div',{staticClass:"col-3 col-lg-6 col-xl-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"citizenship"}},[_vm._v("Гражданство")]),_c('div',{staticClass:"position-relative"},[_c('select',{staticClass:"form-control",class:{
                           'is-ok': !passenger.errors.citizenship,
                           'is-error' : passenger.errors.citizenship},attrs:{"id":'citizenship'+index},domProps:{"value":passenger.citizenship},on:{"input":function($event){return _vm.validateForm([index,'citizenship',$event]);}}},_vm._l((_vm.getCitizenships),function(option){return _c('option',{key:option.code,staticClass:"form-option",domProps:{"value":option.code,"selected":option.code === '643'}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0),_c('div',{staticClass:"error-feedback",class:{'d-none': !passenger.errors.citizenship}},[_vm._v(_vm._s(passenger.errors.citizenship))])])]),_c('div',{staticClass:"col-3 col-lg-6 col-xl-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"document"}},[_vm._v("Документ")]),_c('div',{staticClass:"position-relative"},[_c('select',{staticClass:"form-control",class:{
                          'is-ok': !passenger.errors.document,
                          'is-error' : passenger.errors.document},attrs:{"id":'document'+index},domProps:{"value":passenger.document},on:{"input":function($event){return _vm.validateForm([index, 'document', $event])}}},_vm._l((passenger.isAdult ? _vm.getDocumentTypes : _vm.getDocumentTypes.filter(opt=>(['4','2','3'].includes(opt.id)))),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(_vm._s(option.name))])}),0),_c('div',{staticClass:"error-feedback",class:{'d-none': !passenger.errors.document}},[_vm._v(_vm._s(passenger.errors.document))])])]),_c('div',{staticClass:"col-3 col-lg-6 col-xl-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"documentInfo"}},[_vm._v("Серия и номер документа")]),_c('input',{staticClass:"form-control",class:{
                        'is-ok': !passenger.errors.documentInfo,
                        'is-error' : passenger.errors.documentInfo},attrs:{"type":"text","id":'documentInfo'+index,"placeholder":"01 23 456789"},domProps:{"value":passenger.documentInfo},on:{"input":function($event){return _vm.validateForm([index,'documentInfo', $event, passenger.document])}}}),_c('div',{staticClass:"error-feedback",class:{'d-none': !passenger.errors.documentInfo}},[_vm._v(_vm._s(passenger.errors.documentInfo))])])])])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }